body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Scotia-Regular';
  font-weight: 400;
  src: local('Scotia'), url('./theme/fonts/woff/Scotia_W_Rg.woff') format('woff'),
    url('./theme/fonts/ttf/Scotia_Rg.ttf') format('truetype');
}
@font-face {
  font-family: 'Scotia-Headline';
  font-weight: 700;
  src: local('Scotia'), url('./theme/fonts/woff/Scotia_W_Headline.woff') format('woff'),
    url('./theme/fonts/ttf/Scotia_Headline.ttf') format('truetype');
}
@font-face {
  font-family: 'Scotia-Bold';
  font-weight: 700;
  src: local('Scotia'), url('./theme/fonts/woff/Scotia_W_Bd.woff') format('woff'),
    url('./theme/fonts/ttf/Scotia_Bd.ttf') format('truetype');
}
@font-face {
  font-family: 'Scotia-Light';
  src: local('Scotia'), url('./theme/fonts/woff/Scotia_W_Lt.woff') format('woff'),
    url('./theme/fonts/ttf/Scotia_Lt.ttf') format('truetype');
}
